import React from 'react';
import {Helmet} from "react-helmet";

const STPrivacy = () => (
  <>
  <div className="relative overflow-hidden bg-white py-16">
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-prose text-lg">
        <h1>
          <span className="block text-center text-lg font-semibold text-blue-600">LAST UPDATED OCTOBER 23 2022</span>
          <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          Sticky Moods Privacy Policy
          </span>
        </h1>
        <p className="mt-8 text-xl leading-8 text-gray-500">
          
        </p>
      </div>
      <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
        <p>
         In short: We don't want to know anything about your device. We don't want to know anything about you.
        </p>
        
        <h2>1.1 Contact Us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at jordi@goodsnooze.com
        </p> 
      </div>
    </div>
  </div>
  </>
);
export default STPrivacy;

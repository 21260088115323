import React from 'react';

const Menubar = () => (
	<div class="lg:relative">
	
	<div class="mx-auto w-full pt-16 pb-20 text-center bg-gray-800 lg:py-48 lg:text-left">
	  <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
	  
		<h1 class="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
		  <span class="block xl:inline">Toggle on or off</span><br/>
		  <span class="block font-extrabold text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-orange-600 ">from the menubar</span>
		</h1>
		
		<p class="mt-3 mx-auto text-2xl text-gray-200 sm:text-2xl md:mt-5">
			Right click the menu bar icon to quickly toggle Vivid on or off.
		</p>
		
	  </div>
	</div>
	
	<div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
	  <img class="absolute inset-0 w-full h-full object-cover" src="/images/menubar.jpg" alt="" />
	</div>
	
  </div>
	
);
export default Menubar;
	

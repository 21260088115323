  
  export default function  () {
	return (
	  <div className="relative bg-gray-800 py-12">
		<div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
		  <div class="pt-2 rounded-2xl">
			<div class="aspect-w-16 aspect-h-9">
  				<iframe src="https://www.youtube.com/embed/-uI_Lc6TMs8" frameborder="0" loading="lazy" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
			</div>
		  </div>
		</div>
	  </div>
	)
  }

import React from 'react';

const Press = () => (

  <div class="bg-gray-800">
	<div class="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
	 
	  <div class="flow-root lg:mt-10">
		<div class="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
		<a href="https://www.techradar.com/news/a-favorite-app-on-macos-gets-updated-to-make-my-macs-display-even-brighter">
		  <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
			<img class="h-12 py-2" src="/images/press/techradar.png" alt="Tuple" />
		  </div>
		  </a>
		<a href="https://www.imore.com/vivid-new-app-doubles-your-macs-display-brightness-system-wide">
		  <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
			<img class="h-12" src="/images/press/imore.png" alt="Tuple" />
		  </div>
		  </a>
		  <a href="https://arstechnica.com/gadgets/2022/04/app-lets-you-crank-the-new-macbook-pros-brightness-to-over-1000-nits/">
		  <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
			<img class="h-12" src="/images/press/arstechnica.png" alt="Mirage"/ >
		  </div>
		  </a>
		  <a href="https://9to5mac.com/2022/10/19/vivid-hdr-app-eclipse-mode-web-browser/">
		  <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
			<img class="h-12" src="/images/press/9to5mac.png" alt="9to5mac"/>
		  </div>
		  </a>
		  <a href="https://www.applesfera.com/aplicaciones-os-x-1/doble-brillo-todo-sistema-vivid-nos-permite-alcanzar-1600-nits-simple-atajo-teclado">
			<div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
			  <img class="h-12" src="/images/press/applesfera.png" alt="Applesfera"/>
			</div>
			</a>
		</div>
	  </div>
	</div>
  </div>
 
);
export default Press;

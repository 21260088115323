
  /* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/solid'
import React, { useEffect } from 'react';

export default function CTA() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://gumroad.com/js/gumroad.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
  <div class="bg-gray-800">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="bg-white rounded-2xl shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div class="lg:self-center">
            <h2 class="text-3xl font-extrabold text-gray-800 sm:text-4xl">
              <span class="block">Try it for free in</span>
              <span class="block">Splitscreen Mode™</span>
            </h2>
            <p class="mt-4 text-lg leading-6 text-gray-800">You can try Vivid in Splitscreen Mode™ for free for as long as you want. See if it works for your workflow before you consider purchasing a lifetime license. </p>
            <a href="https://gumroad.com/a/812393587" class="bg-gradient-to-br from-yellow-400 to-orange-600 mt-8 bg-white rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white hover:bg-white">Try Vivid Now</a>
          </div>
        </div>
        <div class="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <img class="transform translate-x-6 translate-y-6 rounded-2xl object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="images/splitscreen2.jpg" alt="App screenshot" />
        </div>
      </div>
    </div>
  </div>

  )
}

import React, { useEffect } from 'react';  
  
  export default function Footer() {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://gumroad.com/js/gumroad.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }, []);
    
    useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://gumroad.com/js/gumroad.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }, []);

    
	return (
		<footer class="bg-gray-800" aria-labelledby="footer-heading">
<div class="max-w-8xl mx-auto px-4 sm:px-6 lg:py-16 lg:px-8">
<div class="border-gray-800 lg:flex lg:items-center lg:justify-between xl:mt-0">
      <div>
      <div class="md:flex md:items-start md:justify-start space-x-1"> 
		<h3 class="text-xl font-bold uppercase text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-orange-600">Vivid</h3>
        <h3 class="text-xl font-semibold text-gray-100 tracking-wider uppercase">Doubles your Brightness, System-wide.</h3>
      </div>
        
      </div>
      <form class="mt-4 sm:flex sm:max-w-md lg:mt-0">
        <a href="javascript:goGumroad()" class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <div class="w-full bg-orange-50 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-yellow-600 hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-orange-500">Purchase License</div>
        </a>
      </form>
    </div>
    <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
      <div class="flex space-x-6 md:order-2">
        <a href="https://www.twitter.com/thevividapp" class="text-gray-400 hover:text-gray-300">
          <span class="sr-only">Twitter</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
        <a href="https://www.getvivid.app/privacy" class="text-gray-400 hover:text-gray-300">
          <span>Privacy Policy</span>
        </a>
        <a href="https://www.impresskit.net/vivid" class="text-gray-400 hover:text-gray-300">
          <span>Presskit</span>
        </a>
      </div>
      <p class="mt-8 text-base text-gray-200 md:mt-0 md:order-1">Made by <a class="underline" href="https://www.twitter.com/jordibruin">Jordi Bruin</a> & <a class="underline" href="https://www.twitter.com/benlumendigital">Ben Harraway</a> with 😎 and ☀️.</p>
    </div>
</div>
		</footer>
	)
  }
  
  
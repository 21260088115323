import React from 'react';

const Splitscreen = () => (
	<div class="lg:relative">
		<div class="mx-auto p-16 bg-gray-800 px-4 sm:px-6">
			<div class="text-center">
			  <h1 class="text-4xl tracking-tight font-bold text-white sm:text-5xl md:text-6xl">
				<span class="block text-2xl">UNLIMITED FREE TRIAL</span>
				<span class="block font-bold uppercase text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-orange-600">Splitscreen Mode™</span>
			  </h1>
			  <p class="mt-3 max-w-md mx-auto text-base text-gray-200 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Use Vivid in Splitscreen Mode™ for as long as you want to see the difference for yourself.</p>
			</div>
		  </div>
		  
		  <div class="bg-gray-800">
		  	<div class="max-w-7xl mx-auto pb-16">
		    	<div class="max-w-7xl px-4 sm:px-6 lg:px-8">
					<div class="grid grid-cols-2 gap-[0rem]">
						<img 
							id="tester" 
							class="rounded-l-3xl"							src="/images/bright.jpg" 
							alt="Example With Vivid" 
						/>
						
						<img 
							class="rounded-r-3xl"  
							src="/images/not-bright.jpg" 
							alt="Example Without Vivid" 
						/>
					</div>
				</div>
			</div>
		</div>
			
		<video autoplay loop muted src="/videos/white1.mp4"></video>
		  <video autoplay loop muted src="/videos/white2.mp4"></video>
		  <video autoplay loop muted src="/videos/white1.webm"></video>
		  

		
		
		
	</div>
);
export default Splitscreen;
	
	
	





import React from 'react';
import { Popover, Transition } from '@headlessui/react'
import {
  BrowserRouter as Router,
  Link } from 'react-router-dom';


const Header = () => (
  <div className="relative bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              
                  <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl">
                  <span className="text-4xl xl:inline text-white">Vivid</span>
                </h1>
            </div>
          </div>
        </div>
      </div>
);
export default Header;

  /* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/solid'
import React, { useEffect } from 'react';

export default function CTA() {

  return (
  <div class="bg-gray-800">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 class="text-3xl font-extrabold text-white">Frequently asked questions</h2>
          <p class="mt-4 text-lg text-gray-200">Can’t find the answer you’re looking for? Reach out to <a href="mailto:support@getvivid.app" class="font-medium text-orange-400 hover:text-orange-400">Jordi & Ben</a>.</p>
        </div>
        <div class="mt-12 lg:mt-0 lg:col-span-2">
          <dl class="space-y-12">
          
            <div>
              <dt class="text-lg leading-6 font-bold text-white">Can I try Vivid before purchasing it?</dt>
              <dd class="mt-2 text-base text-gray-200">Yep! When you install Vivid it will start in Splitscreen Mode™, where half of your screen will be able to go into full brightness mode. This way you can check to see if Vivid works for your particular usecases. You can use Vivid in Splitscreen Mode™ for as long as you want (but we think you'll want to use it on both sides once you see it 😉)</dd>
            </div>
            
            <div>
              <dt class="text-lg leading-6 font-bold text-white">Will Vivid break my display or reduce its lifespan?</dt>
              <dd class="mt-2 text-base text-gray-200">Vivid does not make your display do anything it’s not supposed to do. Vivid enables the extra brightness range usually reserved for HDR content, in exactly the same as if you were editing HDR video.
              
              The official word from Apple on this topic is <u><a href="https://support.apple.com/en-gb/guide/pro-display-xdr/apdffb1a5d7d/mac">here</a></u>. Apple claims the following:<br/><br/>
              
              <i>"1000 nits of brightness. Pro Display XDR can sustain 1000 nits of brightness across the full screen. This means that a pro can edit an HDR photograph or video with the entire frame at 1000 nits of brightness, indefinitely."</i><br/><br/>
              
              Vivid does not use low level display hacks to push your display to levels it shouldn't go. The temperature of your displays will increase if you use your display in a very warm environment, but macOS will limit the max brightness if necessary.
              </dd>
            </div>
            
            <div>
              <dt class="text-lg leading-6 font-bold text-white">Which monitors and MacBooks are supported?</dt>
              <dd class="mt-2 text-base text-gray-200">Vivid is made for MacBook Pros with the M1 Pro or M1 Max chip, as well as the Pro Display XDR display. The monitors on these devices have the capability to display content at 1.000 nits sustained (for an indefinite time). Vivid also supports other third party HDR monitors to some extent. Unfortunately the M1/M2 MacBook Air, the M1 MacBook Pro and the Studio Display are not supported as their screens can not go beyond their regular maximum.</dd>
            </div>
            
            <div>
              <dt class="text-lg leading-6 font-bold text-white">Does Vivid cause any CPU overhead to my system?</dt>
              <dd class="mt-2 text-base text-gray-200">Nope, at least nothing noticeable!</dd>
            </div>
            
            <div>
              <dt class="text-lg leading-6 font-bold text-white">Will Vivid work with my non-Apple HDR monitors?</dt>
              <dd class="mt-2 text-base text-gray-200">The short answer is yes! The longer answer is, it might! Try out the free trial and let us know if you see anything unexpected, as we would love to increase support for more monitors.</dd>
            </div>
            
            <div>
              <dt class="text-lg leading-6 font-bold text-white">Can I also buy Vivid on the Mac App Store?</dt>
              <dd class="mt-2 text-base text-gray-200">Yes, although it will be a bit more expensive there than if you would use the direct download. You can get it <u><a href="https://apps.apple.com/nl/app/vivid-double-your-brightness/id6443470555?l=en&mt=12">here</a></u>!</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>


  )
}





import React from 'react';

import Hero from '../Components/Hero';
import Header from '../Components/Header';
import CTA from '../Components/CTA';
import Footer from '../Components/Footer';
import Video from '../Components/Video';
import Safety from '../Components/Safety';

import Splitscreen from '../Components/Features/Splitscreen';
import OSD from '../Components/Features/OSD';
import Outside from '../Components/Features/Outside';
import Menubar from '../Components/Features/Menubar';
import FAQ from '../Components/FAQ';
import Team from '../Components/Team';
import Press from '../Components/Press';

const Home = () => (
  <>
  <div id="floatingTopBar" class="relative bg-gradient-to-r from-blue-400 to-blue-600">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:text-center sm:px-16">
        <p class="font-medium text-white">
          <span class="md:hidden">Brighten up your new MacBook Pro! 💻 Use code M2PRO for 10% off.</span>
          <span class="hidden md:inline">Brighten up your new MacBook Pro! 💻 Use code M2PRO for 10% off.</span>
          <span class="block sm:ml-2 sm:inline-block">            
            <a href="https://gumroad.com/a/812393587" class="text-white font-bold underline"> Buy now <span aria-hidden="true">&rarr;</span></a>
          </span>
        </p>
      </div>
      <div class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
        <button type="button" class="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
          <span class="sr-only">Dismiss</span>
          
          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <Header />
  
  

    <Hero />
    <Press />
    <Video />
    <OSD />
    
    <Outside />
    <Menubar />
    <Splitscreen />
    <CTA />
    <Team />
    <FAQ />
    <Footer />
  </>
);
export default Home;

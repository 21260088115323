import React from 'react';
import {Helmet} from "react-helmet";

const Privacy = () => (
  <>
  <div className="relative overflow-hidden bg-white py-16">
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-prose text-lg">
        <h1>
          <span className="block text-center text-lg font-semibold text-blue-600">LAST UPDATED OCTOBER 19 2022</span>
          <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          Vivid Privacy Policy
          </span>
        </h1>
        <p className="mt-8 text-xl leading-8 text-gray-500">
          
        </p>
      </div>
      <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
        <p>
         In short: We don't want to know anything about your device. We don't want to know anything about you. We have three analytics events, all related to license validation.
        </p>
        
        <h2>1.0 Information we collect</h2>
        <p>
          We collect where you installed Vivid from (Gumroad, Mac App Store or SetApp) so that we can handle the license validation correctly. We don't collect any other information.
        </p>
        
        <h2>1.1 Information you provide to us</h2>
        <p>
          No information can be provided to us through the Vivid app. If you run into problems or bugs we have a dedicated screen debug app that we will share with you.
        </p>
        
        <h2>1.2 Links to Other Sites</h2>
        <p>
          From the Gumroad version of the app you can be redirected to Gumroad to buy a license. We are not responsible for content that appears on other sites.
        </p>
        
        <h2>1.3 Changes to This Privacy Policy</h2>
        <p>
          If we update this privacy policy we will inform customers via an email and we will add an alert on launch of the new version of the app.
        </p>
        
        <h2>1.4 Contact Us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@getvivid.app
        </p> 
      </div>
    </div>
  </div>
  </>
);
export default Privacy;

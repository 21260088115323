/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link 
} from 'react-router-dom';


export default function Example() {

  useEffect(() => {

     var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://api.countapi.xyz/hit/com.goodsnooze.vivid/home");
    xhr.responseType = "json";
    xhr.onload = function() {
        document.getElementById('visits').innerText = this.response.value;
    }
    xhr.send();
  }, []);
  
  const [downloadURL, setDownloadURL] = useState(0);
  useEffect(() => {    
    const url = window.location.href 
    const ref = url.substring(url.indexOf('=') + 1);
    
    const newURL = 'https://lumen-digital.com/apps/vivid/download_ref?ref=' + ref
    
    setDownloadURL(newURL)
    console.log(downloadURL);
  })

  return (

  <div class="relative bg-gray-50">
    <main class="lg:relative">
    
      <div class="relative w-full h-96 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img class="absolute inset-0 w-full h-full object-cover" src="/images/header.jpeg" alt="" />
      </div>
      
      <div class="mx-auto bg-gray-800 w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div class="px-4 lg:w-1/2 sm:px-8 mx-8 xl:pr-16">
        
          <h1 class="text-6xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span class="block text-white xl:inline">Double Brightness</span>
            <span class="
              block text-6xl py-4 font-extrabold text-transparent bg-clip-text 
              bg-gradient-to-br from-yellow-400 to-orange-600">
            System-Wide</span>
          </h1>
          
          <p class="mt-3 max-w-md text-2xl text-gray-200 sm:text-2xl md:mt-5 md:max-w-3xl">Unlock the full brightness of your MacBook Pro and Pro Display XDR with Vivid</p>
          
          <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
          <script src="https://gumroad.com/js/gumroad.js"></script>
          
          
            {/* <div class="rounded-md shadow">
              <a href="javascript:goGumroad()" class="w-full flex items-center justify-center px-8 py-3 text-base font-bold rounded-2xl text-white 
              bg-gradient-to-r from-yellow-400 to-orange-600
              
              hover:bg-indigo-700 md:py-4 md:text-xl md:px-10">Purchase License</a>
            </div> */}
            
            {/* <div class="rounded-md shadow">
            <a class="w-full flex items-center justify-center px-8 py-3 text-base font-bold rounded-2xl text-white 
            bg-gradient-to-r from-yellow-400 to-orange-600 hover:bg-indigo-700 md:py-4 md:text-xl md:px-10" href="https://goodsnooze.gumroad.com/l/vivid" data-gumroad-overlay-checkout="true">
            Purchase License</a>
            </div> */}
            
            <div class="rounded-md shadow">
              <a href="https://gumroad.com/a/812393587" class="w-full flex items-center justify-center px-8 py-3 text-base font-bold rounded-2xl text-white 
              bg-gradient-to-r from-yellow-400 to-orange-600
              
              hover:bg-indigo-700 md:py-4 md:text-xl md:px-10">Purchase License</a>
            </div>
            
            
            <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a href={downloadURL} class="w-full flex items-center justify-center px-8 py-3 text-base font-medium rounded-2xl text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-xl md:px-10">Try for free</a>
            </div>
          </div>
          
          {/* <p class="mt-3 max-w-md text-sm text-gray-200 sm:text-sm md:mt-5 md:max-w-3xl">Or get it from the <u><a href="https://apps.apple.com/nl/app/vivid-double-your-brightness/id6443470555?l=en&mt=12">Mac App Store</a></u></p> */}
          
          <p class="mt-3 max-w-md text-sm text-gray-200 sm:text-sm md:mt-5 md:max-w-3xl">Requires MacBook Pro 14 or 16 inch with M1/M2 Pro/Max or Pro Display XDR</p>
        </div>
      </div>
    </main>
  </div>
  )
}








import './App.css';
import Hero from './Components/Hero';
import Header from './Components/Header';
import CTA from './Components/CTA';
import Footer from './Components/Footer';
import Features from './Components/Features';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';

import Home from './pages/home.js';
import Privacy from './pages/privacy.js';

import STPrivacy from './pages/stprivacy.js';
import STTerms from './pages/stterms.js';

import PPPrivacy from './pages/ppprivacy.js';
import PPTerms from './pages/ppterms.js';

import SMPrivacy from './pages/smprivacy.js';
import MWPrivacy from './pages/mwprivacy.js';

import Documentation from './pages/documentation.js';

function App() {

  return (
    <div className="App">
      <Router>
      <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/privacy' element={<Privacy/>} />
          
          <Route path='/stprivacy' element={<STPrivacy/>} />
          <Route path='/stterms' element={<STTerms/>} />
          
          <Route path='/ppprivacy' element={<PPPrivacy/>} />
          <Route path='/ppterms' element={<PPTerms/>} />
          
          <Route path='/smprivacy' element={<SMPrivacy/>} />
          <Route path='/mwprivacy' element={<MWPrivacy/>} />
      </Routes>
      </Router>
    </div>
  );
}

export default App;

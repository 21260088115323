import React from 'react';

const Team = () => (
	<div class="bg-gray-800">
	  <div class="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
		<div class="space-y-12">
		  <div class="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl text-white ">
			<h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Behind the scenes</h2>
			<p class="text-xl text-gray-200">Vivid is made by Ben and Jordi, two indie developers from the Netherlands and the United Kingdom.</p>
		  </div>
		  
		  <ul role="list" class="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-2 lg:max-w-5xl">
		  
			
			  <li class="space-y-6">
				<img class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src="/images/jordi.jpg" alt="Jordi Bruin" />
				<div class="space-y-2">
				  <div class="text-xl leading-6 font-medium space-y-1 text-white">
					<h2>Jordi Bruin</h2>
				  </div>
				  <ul role="list" class="flex justify-center space-x-5">
					<li>
					  <a href="https://www.twitter.com/jordibruin" class="text-white hover:text-gray-100">
						<span class="sr-only">Twitter</span>
						<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
						  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
						</svg>
					  </a>
					</li>
				  </ul>
				</div>
			  </li>
			
			
			
			  <li class="space-y-6">
				<img class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src="/images/ben.jpg" alt="Ben Harraway" />
				<div class="space-y-2">
				  <div class="text-xl leading-6 font-medium space-y-1 text-white">
					<h2>Ben Harraway</h2>
				  </div>
				  <ul role="list" class="flex justify-center space-x-5">
					<li>
					  <a href="https://www.twitter.com/benlumendigital" class="text-white hover:text-gray-100">
						<span class="sr-only">Twitter</span>
						<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
						  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
						</svg>
					  </a>
					</li>
				  </ul>
				</div>
			  
			</li>
	
			
		  </ul>
		</div>
	  </div>
	</div>
	
);
export default Team;
	




import React from 'react';

const MWPrivacy = () => (
  <>
  <div className="relative overflow-hidden bg-white py-16">
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-prose text-lg">
        <h1>
          <span className="block text-center text-lg font-semibold text-blue-600">LAST UPDATED FEBRUARY 5 2023</span>
          <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          MacWhisper Privacy Policy
          </span>
        </h1>
        <p className="mt-8 text-xl leading-8 text-gray-500">
          
        </p>
      </div>
      <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
        <p>
         In short: We don't want to know anything about your device. We don't want to know anything about you. 
        </p>
        
        <h2>1.1 App Functionality</h2>
        <p>
          MacWhisper does all it's functionality on your device. No data (audio, text or other) leaves your device.
        </p> 
        
        <h2>1.2 Analytics</h2>
        <p>
          MacWhisper does not include any analytics.
        </p> 
        
        <h2>1.3 Contact Us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at jordi@goodsnooze.com
        </p> 
      </div>
      <div className="mx-auto max-w-prose text-lg">
        <h1>
          <span className="block text-center text-lg font-semibold text-blue-600">LAST UPDATED FEBRUARY 5 2023</span>
          <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          MacWhisper Terms of Use
          </span>
        </h1>
        <p className="mt-8 text-xl leading-8 text-gray-500">
          
        </p>
      </div>
      <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
        <p>
         Whisper Transcription is a local speech transcription app for macOS that lets you transcribe notes, interviews, video footage and more.
        </p>
        
        <h2>Features</h2>
        <p>
          Whisper Transcription does not have any guarantees in regards to the accuracy and effectiveness of the functionality in the app. Transcriptions can not be 100% accurate in all conditions, even on the highest quality level. 
        </p> 
        
        <h2>Pro Version</h2>
        <p>
          The pro version unlocks the Tiny (English only), Medium and Large transcription quality modes. The features that are available in Pro can change over time depending on technical implementation details or for any other reason. Users can purchase a renewable subscription or unlock all features with a one time payment.
        </p> 
        
        <h2>Questions</h2>
        <p>
          If you have questions, feature suggestions or bug reports please reach out to jordi@goodsnooze.com.
        </p> 
      </div>
    </div>
  </div>
  </>
);
export default MWPrivacy;

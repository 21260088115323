import React from 'react';

const Safety = () => (

  <div class="relative bg-gray-800">
	<div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
	  <div class="lg:col-start-2 py-8">
		<h3 class="text-4xl font-extrabold text-white tracking-tight sm:text-4xl pb-4">Take your MacBook</h3>
		<h3 class="text-4xl font-extrabold text-white tracking-tight sm:text-4xl text-4xl text-transparent bg-clip-text bg-gradient-to-br from-yellow-400 to-orange-600">into the 🔆 Sunshine </h3>
		
		<p class="mt-3 text-lg text-gray-500">The extra brightness comes in handy when you're working outside or in a brightly lit environment.</p>
		</div>
	  <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
		<img class="relative mx-auto" src="/images/outside.jpeg" alt=""/>
	  </div>
	</div>
  </div>
  


);
export default Safety;



import React from 'react';
import {Helmet} from "react-helmet";

const PPTerms = () => (
  <>
  <div className="relative overflow-hidden bg-white py-16">
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-prose text-lg">
        <h1>
          <span className="block text-center text-lg font-semibold text-blue-600">LAST UPDATED NOVEMBER 28 2022</span>
          <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          Posture Pal Terms of Use
          </span>
        </h1>
        <p className="mt-8 text-xl leading-8 text-gray-500">
          
        </p>
      </div>
      <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
        <p>
         Posture Pal lets you use the motion sensors in your AirPods Pro (or similar) to keep track of your posture.
        </p>
        
        <h2>Features</h2>
        <p>
          The information displayed in Posture Pal is based on the motion sensor data in your AirPods and should not be seen as medical advice. The accuracy and effectiveness of the alerts and data shown in the app is not a replacement for other medical procedures and should be used as such.
        </p> 
        
        <h2>Pro Version</h2>
        <p>
          The pro version unlocks a range of extra features that make the app experience better. The features that are available in Pro can change over time depending on technical implementation details or for any other reason. Users can purchase a renewable subscription or unlock all features with a one time payment.
        </p> 
        
        <h2>Questions</h2>
        <p>
          If you have questions, feature suggestions or bug reports regarding Posture Pal please reach out to jordi@goodsnooze.com.
        </p> 
      </div>
    </div>
  </div>
  </>
);
export default PPTerms;
